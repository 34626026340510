var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.isPersonalWallet ||
    (!_vm.isPersonalWallet &&
      _vm.currentOrg &&
      _vm.user &&
      _vm.currentOrg.created_by.toString() === _vm.user._id.toString())
  )?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"mb-1"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [(_vm.user && _vm.user.shareCode)?_c('v-btn',_vm._g({attrs:{"icon":"","disabled":!_vm.sponsorLinkStatus,"large":""},on:{"click":function($event){return _vm.copy(_vm.sponsorLink)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()]}}],null,false,1796060841)},[_c('span',[_vm._v("Copy SponsorLink")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.isGenerating,"large":""},on:{"click":_vm.regenerateLink}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,557844652)},[_c('span',[_vm._v("Regenerate Sponsor Link")])])],1),_c('div',{staticClass:"mb-1"},[_c('v-switch',{attrs:{"loading":_vm.isChanging,"label":"Enable sponsor link"},on:{"click":_vm.toggleLink},model:{value:(_vm.sponsorLinkStatus),callback:function ($$v) {_vm.sponsorLinkStatus=$$v},expression:"sponsorLinkStatus"}})],1),(!_vm.isPersonalWallet)?_c('div',{staticClass:"mb-1"},[_c('v-switch',{attrs:{"loading":_vm.isGlobalAutoSponsoring,"label":"Enable auto sponsoring for new stakeholders"},on:{"click":_vm.toggleAutoSponsoringStatus},model:{value:(_vm.autoSponsoring),callback:function ($$v) {_vm.autoSponsoring=$$v},expression:"autoSponsoring"}})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }