























































import { ref, computed } from '@vue/composition-api';
import store, { useProgramActions, useUserState } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';

export default {
  props: {
    currentOrg: {
      type: Object,
      default: () => {}
    },
    isPersonalWallet: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const isGenerating = ref(false);
    const isChanging = ref(false);
    const isGlobalAutoSponsoring = ref(false);
    const { user } = useUserState(['user']);
    const { regenerateSponsorLink, toggleSponsorLinkSwitch, toggleAutoSponsoringSwitch } =
      useProgramActions([
        'regenerateSponsorLink',
        'toggleSponsorLinkSwitch',
        'toggleAutoSponsoringSwitch'
      ]);

    const sponsorLinkStatus = computed({
      get: () => {
        if (props.isPersonalWallet) {
          return user.value?.sponsorLinkSharable;
        }
        return props.currentOrg?.sponsorLinkSharable;
      },
      set: newVal => {
        ctx.emit('input');
      }
    });

    const autoSponsoring = computed({
      get: () => {
        if (props.isPersonalWallet) {
          return user.value?.isAutoSponsoring;
        }
        return props.currentOrg?.isAutoSponsoring;
      },
      set: newVal => {
        ctx.emit('input');
      }
    });

    const sponsorLink = computed(() => {
      if (props.isPersonalWallet) {
        return `https://www.pilotcity.com/sponsor/${user.value?.shareCode}`;
      }
      if (props.currentOrg?.users) {
        const currentUser = props.currentOrg?.users?.find(u => u.user_id === user.value?._id);
        return `https://www.pilotcity.com/sponsor/${currentUser?.shareCode}`;
      }
      return 'No SponsorLink available';
    });

    const copy = str => {
      navigator.clipboard.writeText(str);
      store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
        message: 'Link Copied',
        type: 'success',
        isShowSnackbar: true
      });
    };

    async function regenerateLink() {
      try {
        let currentOrgUser;
        if (!props.isPersonalWallet) {
          currentOrgUser = props.currentOrg?.users?.find(u => u.user_id === user.value?._id);
        }
        const payload = {
          share_code: props.isPersonalWallet ? user?.value?.shareCode : currentOrgUser?.shareCode,
          link_type: props.isPersonalWallet ? 'personal' : 'organization'
        };
        isGenerating.value = true;
        const res: any = await regenerateSponsorLink(payload);
        if (res) {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'SponsorLink Regenerated Successfully',
            type: 'success',
            isShowSnackbar: true
          });
          if (payload.link_type === 'organization') ctx.emit('update-org', res);
          isGenerating.value = false;
        }
      } catch (error) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Something went wrong',
          type: 'error',
          isShowSnackbar: true
        });
      }
    }

    async function toggleLink() {
      isChanging.value = true;
      const payload = {
        document_id: props.isPersonalWallet ? user.value?._id : props.currentOrg?._id,
        type: props.isPersonalWallet ? 'User' : 'Organization'
      };
      const res = await toggleSponsorLinkSwitch(payload);
      if (res) {
        if (payload.type === 'Organization') ctx.emit('update-org', res);
      }
      isChanging.value = false;
    }

    async function toggleAutoSponsoringStatus() {
      isGlobalAutoSponsoring.value = true;
      const payload = {
        document_id: props.isPersonalWallet ? user.value?._id : props.currentOrg?._id,
        type: props.isPersonalWallet ? 'User' : 'Organization',
        switch_type: 'global'
      };
      const res = await toggleAutoSponsoringSwitch(payload);
      if (res) {
        if (payload.type === 'Organization') ctx.emit('update-org', res);
      }
      isGlobalAutoSponsoring.value = false;
    }

    return {
      copy,
      toggleLink,
      toggleAutoSponsoringStatus,
      isGlobalAutoSponsoring,
      isChanging,
      isGenerating,
      regenerateLink,
      sponsorLink,
      user,
      sponsorLinkStatus,
      autoSponsoring
    };
  }
};
